//Main colors
$primary: #003263;
$primaryLight: #004184;

//Secondary colors
$secondary: #F6AF0A;
$secondaryDark: #E0A200;
$grey: #888b8c;
$light-grey: #d1d4d5;

$success: #6eb245;

//Button colors
$darkButton: #061F3D;
$lightButton: #BBD0E8;

//Titles
$titleLight: #efefef;
$titleDark: #101B23;

$dark: #3c3c3c;

$menuTextColor: $titleLight;

$colors: (
        "primaryLight": $primaryLight,
        "secondaryDark": $secondaryDark,
        "primary": $primary
)