.switch__wrapper {
  position: relative;
  background-color: $dark;
  height: $switchHeight;
  width: $switchWidth;
  border-radius: 34px;
  -webkit-transition: $switchTransition;
  transition: $switchTransition;
}

.switch__knob {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  height: $knobSize;
  width: $knobSize;
  left: 4px;
  bottom: 4px;
  -webkit-transition: $switchTransition;
  transition: $switchTransition;
  transition-timing-function: ease-in-out;
  border-radius: 50%;
  border: none;
}

.switch__first-icon {
  border: none;
  position: absolute;
  transition: opacity $switchTransition/2 ease-in, visibility $switchTransition/2;
  opacity: 1;
  visibility: visible;
}

.switch__second-icon {
  border: none;
  position: absolute;
  transition: opacity $switchTransition/2 ease-out, visibility $switchTransition/2;
  opacity: 0;
  visibility: hidden;
}

.switch__input {
  display: none;
}

.switch__second {
  display: none;
}

.switch__input:checked + .switch__wrapper {
  -webkit-transition: .5s;
  transition: .5s;
  background-color: $dark;
}

.switch__input:checked + .switch__wrapper .switch__knob {
  .switch__second {
    display: block;
  }

  -webkit-transform: translateX($switchWidth - $knobSize - $switchGap);
  -ms-transform: translateX($switchWidth - $knobSize - $switchGap);
  transform: translateX($switchWidth - $knobSize - $switchGap);

  //Switch icons around
  .switch__first-icon {
    transition: opacity $switchTransition/2 ease-out, visibility $switchTransition/2;
    opacity: 0;
    visibility: hidden;
  }
  .switch__second-icon {
    //Easy in
    transition: opacity $switchTransition/2 ease-in, visibility $switchTransition/2;
    opacity: 1;
    visibility: visible;
  }
}
/*
$switchWidth: 26px;

!* The switch - the box around the slider *!
.switch {
  position: relative;
  display: inline-block;
  width: 500px;
  height: 34px;
}

!* Hide default HTML checkbox *!
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

!* The slider *!
.slider {
  width: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $light-grey;
  -webkit-transition: .5s;
  transition: .5s;
}

.slider:before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  content: "\f508";
  height: 26px;
  width: $switchWidth*2;
  left: 4px;
  bottom: 4px;
  background-color: $secondaryLight;
  -webkit-transition: .5s;
  transition: .5s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(500px - $switchWidth*2 - 8px);
  -ms-transform: translateX(500px - $switchWidth*2 - 8px);
  transform: translateX(500px - $switchWidth*2 - 8px);
}

!* Rounded sliders *!
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 34px;
}*/
