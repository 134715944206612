.about-me {
  height: 100%;
  padding: 2rem 3rem;
  background-color: $primaryLight;
  border-right: 5px $primary solid;
}

.about-me__photo {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    -webkit-box-shadow: 150px 150px 0px 150px $primary;
    -moz-box-shadow: 150px 150px 0px 150px $primary;
    box-shadow: 150px 150px 0px 150px $primary;
  }
}

.about-me__contact {
  font-size: 1.2rem;

  svg {
    width: 2.5rem !important;
    margin-right: 1rem;
    font-size: 1.6rem;
    color: $secondary;
  }

  .about-me__contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.about-me__hobbies {
  display: flex;

  svg {
    font-size: 2rem;
    margin-right: 2rem;
  }
}
//Media queries
@media screen and (max-width: 1400px) {
  .about-me {
    padding: 2rem 1.5rem;
  }
}

@media screen and (max-width: $lg) {
  .about-me {
    padding: 4rem 3rem;
  }
}

@media screen and (max-width: $sm) {
  .about-me {
    padding: 2rem 10px;
  }
}