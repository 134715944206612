body, html {
  overflow-x: hidden;
}

.no-padding {
  padding: 0;
}

.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}

.no-padding-y {
  padding-top: 0;
  padding-bottom: 0;
}

.no-margin {
  margin: 0;
}

.no-margin-x {
  margin-left: 0;
  margin-right: 0;
}

.no-margin-y {
  margin-top: 0;
  margin-bottom: 0;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}