@import "/00-settings/global";
@import "/00-settings/colors";

* {
  font-family: $font;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@import "/03-elements/form";
@import "/03-elements/titles";
@import "/03-elements/links";
@import "/03-elements/hr";
@import "/03-elements/p";
@import "/03-elements/img";

@import "04-components/bootstrap";
@import "/04-components/grid";
@import "/04-components/spacers";
@import "/04-components/header";
@import "/04-components/buttons";
@import "/04-components/service";
@import "/04-components/contact-form";
@import "/04-components/wrapper";
@import "/04-components/spinner";
@import "/04-components/title";
@import "/04-components/portfolio";
@import "04-components/switch";
@import "04-components/about-me";
@import "04-components/experience";
@import "04-components/attention";
@import "04-components/dark";


@import "/05-utilities/font";
@import "05-utilities/spacing";