.experience {
  height: 100%;
  background-color: $light-grey;
  overflow: auto;
  padding: 2rem 3rem;
}

.experience__item {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  margin: 0 0 1.5rem 1rem;
  border-left: 5px $primary solid;

  div {
    display: flex;
  }

  h4, h5, h6 {
    margin: 0.25rem;

    svg {
      margin: 0 .5rem;
    }
  }
}

.experience__item.experience__item--light {
  border-left: 5px $secondary solid;
}

.skills__label {
  font-size: 2rem;
}

.skills__item {
  margin: 0 0 1.5rem 1rem;

  svg {
    margin-left: 10px;
  }
}

@media screen and (max-width: $sm) {
  .experience {
    padding: 2rem 10px;
  }

  .experience__item {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin: 0 0 1.5rem 0;
    border-left: 0px !important;

    div {
      display: block;
    }
  }

  .skills__item {
    margin: 0 0 1.5rem 0;
  }
}