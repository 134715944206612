.dark-theme {
  .sidenav {
    background-color: #262626;
  }
  .btn-primary {
    background-color: #1f1f1f;
    border: none;
    outline: none !important;
  }

  .btn-secondary {
    background-color: #787878;
    border: none;
    outline: none !important;
  }

  header {
    background-color: $dark;
    background-image: url("/nightsky smaller.png");
    background-size: cover;
    background-position: center;
  }

  header > div.py-5.container > div > div.d-none.d-lg-block.col img {
    display: none;
  }

  .content_wrapper {
    background-color: $dark !important;
  }

  .text-dark, .textdark, p, .textMain {
    color: $light !important;
  }

  .service .text-dark {
    color: $dark !important;
  }
  .service {
    p {
      color: $dark !important;
    }
  }

  .form-wrapper, .portfolio-item .portfolio-item__details, .portfolio-item--wrapper:nth-child(even) .portfolio-item .portfolio-item__details {
    background-color: rgba(183, 183, 183, 0.24);
  }

  #contact-form .wrapper .social, #sub-header {
    background-color: #1f1f1f;
  }

  input, select, textarea{
    background-color: $dark !important;
    border: none;
    color: $light !important;
  }

  textarea:focus, input:focus, #contact-form > div > div.form-wrapper > form > p {
    color: $light !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $light !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $light !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $light !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $light !important;
  }
}