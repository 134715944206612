.content_wrapper {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.wrapper-shadow {
  -webkit-box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.19);
  box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.19);
}

@media screen and (max-width: $md) {
  .content_wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: $sm) {
  .content_wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}