.edk-spinner {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  div {
    background-color: $secondary;
  }
}