.textlight {
  color: $titleLight !important;
}

.textdark {
  color: $titleDark !important;
}

.textMain {
  color: #003263;
}
.textMainL {
  color: #004184;
}

.textMain {
  color: #003263;
}

.textSecondary {
  color: #F6AF0A;
}

.textSecondaryL {
  color: #E0A200;
}

.textSuccess {
  color: $success;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.fw-regular {
  font-weight: normal;
}

.fs-small {
  font-size: 1rem;
}

.fs-smaller {
  font-size: 0.8rem;
}

.fs-medium {
  font-size: 1.3rem;
}

.fs-large {
  font-size: 2rem;
}

.fs-larger {
  font-size: 3rem;
}

.f-center {
  text-align: center;
}

.text-attention {
  color: $secondary;
}