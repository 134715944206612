#contact-form {
  .wrapper {
    width: 100%;

    .social {
      flex-basis: 0;
      padding: 6em 2em;
    }

    .social {
      flex-grow: 1;
      background-color: $primary;

      p, a {
        color: $titleLight;
        font-size: 1.2rem;
      }
    }

    .form-wrapper {
      flex-grow: 0;
      flex-basis: 70%;
      padding:6em 17em 6em 2em;
    }
  }
}

.form-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: $xl){
  #contact-form {
    .wrapper {
      .social, form {
        padding: 2em 1em;
      }

      .form-wrapper {
        padding-right: 1em;
      }
    }
  }
}

@media screen and (max-width: $md){
  #contact-form {
    .wrapper {
      flex-wrap: wrap;
    }
  }
  .social, .form-wrapper {
    flex-basis: 100%;
    flex-grow: 1;
  }

  #contact-form .wrapper .form-wrapper {
    flex-basis: 100%;
    padding: 0;
  }
}