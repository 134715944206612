.portfolio-item {
  margin-bottom: 16rem;

  display: flex;
  height: 500px;

  div {
    flex-grow: 1;
    flex-basis: 0;
  }

  .portfolio-item__details {
    height: 500px;
    margin-top: 10rem;
    padding: 4rem 10rem 4rem 4rem;
    background-color: #f8f9fa;
  }

  .portfolio-item__image {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: white;
    margin-left: -4rem;
    height: 500px;
    width: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.portfolio-item--wrapper:nth-child(even) .portfolio-item {
  flex-direction: row-reverse;

  .portfolio-item__details {
    height: 500px;
    margin-top: 10rem;
    padding: 4rem 4rem 4rem 10rem;
    background-color: #f8f9fa;
  }

  .portfolio-item__image {
    margin-right: -4rem;
  }
}

@media only screen and (max-width: 1300px) {
  .portfolio-item__image {
    height: 400px !important;
    width: 400px !important;
  }

  .portfolio-item__details {
    height: 400px;
    margin-top: 10rem;
    padding: 3rem 7rem 3rem 3rem;
    background-color: #f8f9fa;
  }

  .portfolio-item--wrapper:nth-child(even) .portfolio-item {
    flex-direction: row-reverse;

    .portfolio-item__details {
      height: 400px;
      padding: 3rem 3rem 3rem 7rem;
    }
  }
}

@media only screen and (max-width: 970px) {
  .portfolio-item {
    height: auto;

    div {
      flex-grow: 0;
      flex-basis: auto;
    }
  }
  .portfolio-item .portfolio-item__image {
    width: 100% !important;
    margin-left: 0;
  }

  .portfolio-item .portfolio-item__details {
    margin-top: 0 !important;
    padding: 2rem 2rem 2rem 2rem !important;
    height: auto !important;
  }

  .portfolio-item {
    flex-direction: column-reverse !important;
  }
}


