#sub-header {
  background-color: $primary;
}

header {
  background-color: $light;
  div.logo {
    display: flex;
    flex-wrap: nowrap;
    margin: 5px 0;
    height: $headerHeight;
  }

  div.logo img {
    max-height: 100%;
    width: auto;
  }

  div.logo h1 {
    font-size: 1.5em;
    height: 100%;
    font-weight: 600;
    margin: 0 0 0 30px;
    white-space: nowrap;
    line-height: $headerHeight;
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    text-align: right;

    li {
      display: inline;
    }

    a.hamburger {
      display: none;
    }

    a {
      color: $menuTextColor;
      letter-spacing: 1px;
      font-size: 1rem;
      font-weight: 500;
      display: inline-block;
      padding: .5em 1em;
      text-decoration: none;
      transition: 0.1s;
    }

    a:hover {
      transition: 0.1s;
      opacity: 0.8;
    }
  }

  @media (max-width: $md) {
    .container {
      padding: 1vh 10px 0 10px;
    }

    nav {
      a {
        display: none !important;
      }

      a.hamburger {
        display: inline !important;
        padding: 0 !important;
        font-size: 2.5em;
      }
    }
  }

  @media (min-width: 1200px) {
    header div.container {
      justify-content: space-between;
    }
  }
}

.header-buttons {
  display: flex;
  flex-wrap: wrap;

  small {
    margin-left: 10px;
    align-self: center;
  }

  label {
    margin-bottom: 0;
  }
}

.header-subtitle {
  color: $dark;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $primary;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: $titleLight;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.header__content {
  position: relative;
  height: 300px;
}

.header__flip .header__website {
  transition: opacity $switchTransition ease-out, visibility $switchTransition, left $switchTransition ease-out;
  opacity: 0;
  visibility: hidden;
  left: -100%;
}

.header__flip .header__freelance {
  transition: opacity $switchTransition ease-in, visibility $switchTransition, left $switchTransition ease-in;
  opacity: 1;
  visibility: visible;
  left: 0;
}

.header__website {
  height: auto;
  position: absolute;
  transition: opacity $switchTransition ease-in, visibility $switchTransition, left $switchTransition ease-in;
  opacity: 1;
  visibility: visible;
  left: 0;

  h1 {
    font-family: $font-text;
  }
}

.header__freelance {
  transition: opacity $switchTransition ease-out, visibility $switchTransition, left $switchTransition ease-out;
  height: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: -100%;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.menu-open {
  width: 250px !important;
  border-right: 6px solid $secondaryDark;
}

