.service {
  background-color: $white;
  padding: 1rem 2rem;
}

.service__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 1rem;
  margin-top: 3rem;
}

.service-item__checkmark {
  color: $success;
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 1100px) {
  .service__item {
    margin: 1rem 0;
  }
}