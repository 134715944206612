@mixin default-input {
  border-radius: 20px;
  padding: 8px 20px;
  border: 1px solid $light-grey;
  width: 100%;
  transition: 0.2s;
  color: $titleDark;
  font-size: 1rem;
  &:focus {
    border-radius: 20px;
    border: 1px solid $primary;
    outline: none;
    transition: 0.2s;
  }

  &::placeholder {
    color: $titleDark;
  }
}

input {
  @include default-input;
}

input[type=range] {
  padding: 8px 0;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none !important; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}


select {
  @include default-input;

  option {
    color: $titleDark;
    font-size: 1rem;
  }
}

textarea {
  @include default-input;
  min-height: 10em;
  resize: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  background: $primaryLight;
  border-radius: 10px;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: $primaryLight;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  background: $primaryLight;
  border-radius: 10px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: $primaryLight;
  border: 0.2px solid #010101;
  border-radius: 10px;
}

input[type=range]:focus::-ms-fill-lower {
  background: $primaryLight;
}

input[type=range]::-ms-fill-upper {
  background: $primaryLight;
  border: 0.2px solid #010101;
  border-radius: 10px;
}

input[type=range]:focus::-ms-fill-upper {
  background: $primaryLight;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid $titleDark;
  height: 26px;
  width: 26px;
  border-radius: 100%;
  background: $lightButton;
  cursor: pointer;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid $titleDark;
  height: 26px;
  width: 26px;
  border-radius: 100%;
  background: $lightButton;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: 1px solid $titleDark;
  height: 26px;
  width: 26px;
  border-radius: 100%;
  background: $lightButton;
  cursor: pointer;
}

