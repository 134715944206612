.button {
  display: inline-block;
  border-radius: 2px;
  font-size: 1.2em;
  transition: 0.2s;
  padding: 8px 25px;
}

.btn-primary {
  background-color: $primaryLight;
  border-color: $primary;

  &:hover {
    background-color: $primary;
    border-color: $primaryLight;
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondaryDark;

  &:hover {
    background-color: $secondaryDark;
    border-color: $secondary;
  }
}

.button-large {
  padding: 8px 40px;
}

.primary {
  background-color: $secondary;
  border: none;
  color: $titleLight !important;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $secondaryDark;
    transition: 0.2s;
  }
}

.transparent {
  border: 2px solid $lightButton;
  background-color: transparent;
  color: $lightButton;
}

.dark {
  background-color: $darkButton;
  color: $titleLight;
}