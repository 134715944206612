p {
  font-family: $font-text;
  font-size: 1.5rem;
  word-spacing: 1px;
}

@media screen and (max-width: $md) {
  p {
    font-size: 1.2rem;
  }
}