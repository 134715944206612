@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700;800;900&display=swap');

$font: 'Montserrat', sans-serif;
$font-text: 'Heebo', sans-serif;
$spacer: 1rem;
$headerHeight: 40px;
$border-radius: 20px;

$xs: 0;
$sm: 576px;
$md: 968px;
$lg: 1100px;
$xl: 1200px;

$grid-columns:      12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
        xs: $xs,
  // Small screen / phone
        sm: $sm,
  // Medium screen / tablet
        md: $md,
  // Large screen / desktop
        lg: $lg,
  // Extra large screen / wide desktop
        xl: $xl
);

$container-max-widths: (
        sm: 600px,
        md: 800px,
        lg: 1000px,
        xl: 1200px
);

$box-shadow: 6px 0 15px -6px #888b8c, -6px 0 8px -6px #888b8c;

//Switch settings
$switchTransition: .2s;
$switchWidth: 70px;
$switchHeight: 30px;
$switchGap: 8px;
$knobSize: $switchHeight - $switchGap;

